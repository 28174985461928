<template>
  <div class="phenom mod-comment-type">
    <div class="phenom-desc">
        <span class="inline-member js-show-mem-menu">
            <span class="u-font-weight-bold">{{ comment.author.first_name}} {{ comment.author.last_name }}</span>
        </span>
        <span class="inline-spacer"> </span>
        <span class="phenom-date quiet">
            <!-- <a class="date js-hide-on-sending js-highlight-link past" dt="2022-01-13T08:50:54.663Z" href="/c/Bs8li4uP/275-nach-einer-pfandr%C3%BCckzahlung-aus-dem-backend-soll-die-info-mail-an-den-kunden-automatisch-versendet-werden#comment-61dfe7ee5287f74d9da14e11" title="January 13, 2022 9:50 AM">Jan 13 at 9:50 AM</a> -->
            <a href="#" class="date past">{{ comment.created_at | formatDateTime }}</a>
        </span>
        <div class="comment-container">
            <div class="action-comment can-view-video markeddown js-comment" dir="auto">
                <div class="current-comment js-friendly-links js-open-card" v-if="editComment == false">
                    <!-- <p>{{ comment.content }}</p> -->
                    <p v-html="$options.filters.mentionBold(comment.content)" style="margin-bottom: 0;"></p>
                </div>
                <div class="comment-box mod-editing-comment" v-if="editComment == true">
                    <Mentionable :keys="['@']" :items="users" offset="6" insert-space>
                        <textarea class="comment-box-input js-text" aria-label="Edit comment" placeholder="" v-model="commentEditForm.content" data-autosize="undefined" style="overflow-wrap: break-word; background-color: #fff;"></textarea>
                        <template #item-@="{ item }">
                            <div class="user">
                                {{ item.username }}
                                <span class="dim">
                                    ({{ item.first_name }} {{ item.last_name }})
                                </span>
                            </div>
                        </template>
                    </Mentionable>
                    <div class="comment-controls u-clearfix">
                        <button type="button" class="btn btn-primary btn-sm" @click="updateComment">Speichern</button>
                        <button type="button" class="btn btn-light btn-sm" @click="disableEditComment"><i class="fas fa-times"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="hide embedly js-embedly"></div>
        <div class="hide unfurled-comment comment-preview"></div>
    </div>

    <div class="phenom-reactions">
        <div class="phenom-meta quiet">
            <span class="js-hide-on-sending middle">
                <a v-if="comment.author.id == $auth.user().id" class="js-edit-action" href="#" @click="enableEditComment">Edit</a> - <a class="js-confirm-delete-action" @click="deleteComment" href="#">Löschen</a>
                <a v-if="comment.author.id != $auth.user().id" class="js-reply-to-action" href="#">Reply</a>
            </span>
        </div>
    </div>
</div>
</template>

<script>
import 'floating-vue/dist/style.css'
import { Mentionable } from 'vue-mention'
export default {

    props:[
        'comment',
        'card',
        'users',
    ],

    emits: ['commentDeleted'],


    components: {
        Mentionable,
    },

    data(){
        return {
            editComment: false,
            commentEditForm: new window.Form({
                content: ''
            }),
        }
    },

    filters: {
        mentionBold: function(value) {
            if (!value) return "";
            value = value.toString();
            let hashReg = /@\w+/gm;
            value = value.replace(hashReg, "<b>$&</b>");
            return value;
        }
    },

    methods:{

        deleteComment(){
            this.$swal({
                    title: "Möchtest du den Kommentar wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios
                            .delete('/cards/' + this.card.id + '/comments/' + this.comment.id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Gelöscht",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });                
                                this.$emit('comment_deleted', this.comment.id);
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        updateComment(){
            this.commentEditForm
                .put('/cards/' + this.card.id + '/comments/' + this.comment.id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.comment.content = this.commentEditForm.content;
                    this.commentEditForm.clear();
                    this.commentEditForm.reset();
                    this.editComment = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        enableEditComment(){
            this.editComment = true;
            this.commentEditForm.content = this.comment.content;
        },

        disableEditComment(){
            this.editComment = false;
            this.commentEditForm.content = '';
        },
    }

}
</script>

<style scoped>
    .phenom {
        /* margin-left: 40px; */
        min-height: 32px;
        padding: 8px 0;
        position: relative;
    }

    .phenom.mod-comment-type .phenom-desc {
        display: block;
    }

    .phenom-desc {
        word-wrap: break-word;
        margin: 0;
    }

    .inline-member {
        cursor: pointer;
    }

    .u-font-weight-bold {
        font-weight: 700;
    }

    .phenom.mod-comment-type .phenom-desc .inline-spacer {
        display: inline-block;
        min-width: 4px;
    }
    .quiet, .quiet a {
        color: #5e6c84;
    }

    .phenom-date {
        font-size: 12px;
        line-height: 14px;
        margin: 0 0 6px;
        min-width: 105px;
    }

    .phenom.mod-comment-type .phenom-desc .phenom-date {
        font-size: 12px;
        font-weight: 400;
        white-space: pre;
    }

    .phenom.mod-comment-type .phenom-meta {
        align-self: flex-start;
    }
    .phenom.mod-attachment-type .phenom-meta, .phenom.mod-comment-type .phenom-meta {
        display: block;
    }
    .phenom-meta {
        display: inline-block;
        font-size: 12px;
        margin: 0;
        min-width: 110px;
    }
    
    .comment-container {
        display: block;
    }

    .window-module .phenom .action-comment {
        cursor: auto;
    }
    .action-comment.can-view-video {
        display: block;
    }
    .action-comment {
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
        box-sizing: border-box;
        clear: both;
        display: inline-block;
        margin: 4px 2px 4px 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .markeddown {
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
    }

    .action-comment .current-comment {
        padding: 8px 12px;
    }

    .action-comment p {
        word-wrap: break-word;
        text-overflow: ellipsis;
    }

    .comment-box.mod-editing-comment {
        padding-bottom: 48px;
    }

    .comment-box {
        padding: 8px 12px;
        position: relative;
        transition-duration: 85ms;
        transition-property: padding-bottom;
        transition-timing-function: ease;
    }

    .comment-box-input {
        -webkit-appearance: none;
        background-color: #fafbfc;
        border: none;
        border-radius: 3px;
        box-shadow: inset 0 0 0 2px #dfe1e6;
        box-sizing: border-box;
        display: block;
        line-height: 20px;
        margin-bottom: 12px;
        outline: none;
        padding: 8px 12px;
        transition-duration: 85ms;
        transition-property: background-color,border-color,box-shadow;
        transition-timing-function: ease;
        background: #fff!important;
        box-shadow: none;
        box-sizing: initial;
        height: 88px;
        margin: 0;
        min-height: 20px;
        padding: 0;
        resize: none;
        width: 100%;
    }

    .comment-controls {
        bottom: 8px;
        left: 12px;
        opacity: 0;
        position: absolute;
        transform: translateY(48px);
        transition-duration: 85ms;
        transition-property: opacity,transform;
        transition-timing-function: ease;
        opacity: 1;
        transform: translateY(0);
    }
</style>